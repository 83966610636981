module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://easymed.dk","noTrailingSlash":"true"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"easymed","short_name":"easymed","start_url":"/","background_color":"#fff","theme_color":"#6a9fb5","display":"standalone","icon":"src/assets/icon-maskable.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png","purpose":"any maskable"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"include_favicon":false,"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","cacheDigest":"2b93e6b4675698136ebafc923f947628"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Vollkorn","Roboto&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
